import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { IDataArrayString } from '../shared/commonHttpInterface';
import { RequestConfigService } from '../shared/request-config.service';
import * as moment from 'moment';
import { SnackbarService } from '../shared/snackbar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemePalette } from '@angular/material/core';
import { QueryDailogComponent } from './querydailog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-chat-details',
  templateUrl: './chatDetails.component.html',
  styleUrls: ['./chatDetails.component.css']
})
export class ChatDetailsComponent implements OnInit {

  subs = new SubSink();
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  matDialogRef: any;

  constructor(private route: ActivatedRoute, private requestConfigService: RequestConfigService, private snackbarService: SnackbarService,
    private spinnerService: NgxSpinnerService, private matDialog: MatDialog) {
    this.typeSelected = 'ball-grid-pulse';
    this.matDialogRef = MatDialogRef<QueryDailogComponent>;
  }
  dataSource: any;
  initColumns: any;
  displayedColumns: any;
  media: any;
  channelName: string = '';
  chatHead: string = '';
  typeSelected: string;
  msgUserId: string = '';
  backButton: boolean = false;
  channelid: number;

  ngOnInit(): void {
    this.spinnerService.show();
    this.subs.sink = this.route.queryParams.subscribe(this.afterRouteWithChatId.bind(this));
    this.initColumns = [
      { name: 'date', display: 'Date' },
      { name: 'views', display: 'Views' },
      { name: 'message', display: 'Message' }
    ];
    this.displayedColumns = this.initColumns.map((col: any) => col.name);
  }

  afterRouteWithChatId(params: IDataArrayString): void {
    this.dataSource = [];
    if (params['channelusername']) {
      this.channelName = params['channelusername'];
      this.chatHead = 'Chat details for ' + this.channelName;
      this.subs.sink = this.requestConfigService.getChannelChats(params['channelusername']).subscribe(this.showChatMessages.bind(this))
    } else {
      this.chatHead = 'Chat details for ' + params['msguserid'];
      this.subs.sink = this.requestConfigService.getMessageChats(params['msguserid']).subscribe(this.showChatMessages.bind(this))
    }

  }

  showChatMessages(result: any): void {
    this.backButton = false;
    if (result.channelMessages.length > 0) {
      const chatMessages = result.channelMessages;
      this.checked = result.monitorStatus
      this.channelid = chatMessages[0].peerId.channelId;
      let i = 0;
      chatMessages.forEach(function (arr: any) {
        var timestamp = +arr['date'];
        // alert(moment(timestamp).format("DD-MM-YYYY  h:mm A"));
        chatMessages[i]['date'] = moment.unix(timestamp).format("DD-MM-YYYY  h:mm A");
        i = i + 1;
      })
      this.dataSource = chatMessages;
    } else {
      this.snackbarService.openSnackBarWithAction('No chat messages found for selected channel.', '');
    }
    this.spinnerService.hide();

  }

  showQueryForm(event: any): void {
    if (event.checked) {
      this.checked = true;
      this.matDialogRef = this.matDialog.open(QueryDailogComponent, {
        data: { name: this.channelName },
        // disableClose: true
      });

      this.subs.sink = this.matDialogRef.afterClosed().subscribe((res: any) => {
        if (res.data.length > 0) {
          this.requestFilterMsgs(res);
        } else {
          this.checked = false;
        }
      });
    } else {
      this.subs.sink = this.requestConfigService.updateMonitorStatus(this.channelid, false).subscribe(this.monitorStatusResult.bind(this));
    }
  }

  private monitorStatusResult(result: any) {
    this.snackbarService.openSnackBarWithAction(result.monitorStatus, '');
  }

  private requestFilterMsgs(res: any) {
    let monitorTexts: any = [];
    for (let i = 0; i < res.data.length; i++) {
      const jsonItems = { 'monitorText': res.data[i].name };
      monitorTexts.push(jsonItems);
    }
    this.subs.sink = this.requestConfigService.storeChatMonitorDetails(monitorTexts, this.channelName, true,
      this.channelid).subscribe(this.monitorResult.bind(this));
  }

  monitorResult(result: any): void {
    let i = 0;
    if ( result.searchedMessages && result.searchedMessages.length > 0) {
      this.backButton = true;
      this.chatHead = 'Searched Messages - Found few results with matching keyword.';
      let searchedChatMessages = result.searchedMessages;
      searchedChatMessages.forEach(function (arr: any) {
        var timestamp = +arr['date'];
        // alert(moment(timestamp).format("DD-MM-YYYY  h:mm A"));
        searchedChatMessages[i]['date'] = moment.unix(timestamp).format("DD-MM-YYYY  h:mm A");
        i = i + 1;
      })
      this.dataSource = searchedChatMessages;
      this.snackbarService.openSnackBarWithAction(result.monitorStatus + ', Found few results with matching keyword.', '');
    } else {
      this.snackbarService.openSnackBarWithAction(result.monitorStatus, '');
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
