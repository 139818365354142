<div style="margin-top:15%">
    <div class="center">
        <div>
            <h1>Enter the new password</h1>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSetPassword()">
            <div class="form-group">
                <label>New Password</label>
                <input matInput formControlName="newPwd" id="newPwd" type="password" class="form-control inputstyle" />
                <div class="alert alert-danger" *ngIf="newPwd.touched && newPwd.invalid">
                    <div *ngIf="newPwd.errors['required']">New Password is required.</div>
                </div>
            </div>
            <div class="form-group">
                <label>Confirm Password</label>
                <input matInput formControlName="confirmPwd" id="confirmPwd" type="password"
                    class="form-control inputstyle" />
                <div class="alert alert-danger" *ngIf="confirmPwd.touched && confirmPwd.invalid">
                    <div *ngIf="confirmPwd.errors['required']">Confirm Password is required.</div>
                </div>
                <div class="alert alert-danger" *ngIf="confirmPwd.valid && form.invalid && form.errors['MustMatch']">
                    Passwords do not match.
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button class="btn btn-primary" mat-stroked-button color="primary" type="submit">Change Password</button>
            </div>

        </form>
    </div>
</div>