<div class="toolbar" role="banner">
  <div class="column">
    <img id="imgLogo" width="40" alt="Social Connect App" src="../assets/icons/logo.png" (click)="showLanding()" />
  </div>
  <div class="column">
    <h2 id="lblHeader" (click)="showLanding()">Social Media Integration Application</h2>
  </div>
  <div class="spacer"></div>
  <div class="column">
    <button id="btnSettings" *ngIf="showSettings" class="btnSetting" mat-button [matMenuTriggerFor]="panel.menu">
      <mat-icon>settings</mat-icon>Settings</button>
    <app-settingsmenu #panel (selectedItem)="getSelectedItem($event)" [items]="items"></app-settingsmenu>
  </div>
</div>