import { Component, Inject, OnInit, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SwPush } from "@angular/service-worker";
import { RequestConfigService } from "src/app/shared/request-config.service";
import { SubSink } from "subsink";
//import { MyStandaloneModule } from "../common/mystandalone.component";



@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})
export class SubscriberComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = "BEv8qFMFgicpoi5UB7M-84_jmHEr5Kd7Bdyns0WDdCHAIRjU825BV4G6tHjy7ai10Oa3z-ebArs7vyF2wlQMHrg";
  readonly dialogRef = inject(MatDialogRef<SubscriberComponent>);
  subs = new SubSink();
  subscriptionObject: any;
  checkIfAlreadySubscribed = false;

  constructor(private swPush: SwPush, @Inject(MAT_DIALOG_DATA) public data: any, private requestConfigService: RequestConfigService) { 
    
  }

  ngOnInit() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(sub => {
      console.log(JSON.parse(JSON.stringify(sub)));
      this.subscriptionObject = JSON.parse(JSON.stringify(sub));
      this.subs.sink = this.requestConfigService.getAllDeskNotifySubscribers().subscribe(this.checkAllSubscribers.bind(this));
    });
  }

  checkAllSubscribers(subscriberList: any) {
    console.log(subscriberList);
    for (let i = 0; i < subscriberList.length; i++) {
      this.checkIfAlreadySubscribed = this.checkForSubscriptionObject(subscriberList[i]);
      if (this.checkIfAlreadySubscribed) {
        break;
      }
    }
    console.log('User subscription status: ' + this.checkIfAlreadySubscribed);
  }

  checkForSubscriptionObject(eachSubscriptionObject: any): boolean {
    if (eachSubscriptionObject.endpoint === this.subscriptionObject.endpoint) {
      return true;
    } else {
      return false;
    }
  }

  subscribeToNotifications() {
    console.log('Called SubscribeToNotification Method');
    console.log(this.data);
    if(this.checkIfAlreadySubscribed) {
      this.subs.sink = this.requestConfigService.deleteSubscriber(this.subscriptionObject).subscribe();
    } else {
      this.subs.sink = this.requestConfigService.addPushSubscriber(this.subscriptionObject).subscribe();
    }
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
    console.log('Called OnNoClickMethod');
  }


}