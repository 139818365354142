import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { RequestConfigService } from '../shared/request-config.service';
import { SnackbarService } from '../shared/snackbar.service';
import { SubSink } from 'subsink';
import * as _ from 'lodash'
import { LanguageJson } from './languageJson';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.css']
})
export class TelegramComponent implements OnInit {

  constructor(private requestConfigService: RequestConfigService, private router: Router, private snackbarService: SnackbarService,
    private spinnerService: NgxSpinnerService,) { 
      this.typeSelected = 'ball-grid-pulse';
    }
  optionSearchDataSource: any;
  optionSearchDSColumns: any;
  displayedOSColumns: any;
  msgSearchDataSource: any;
  msgSearchDSColumns: any;
  displayedMSColumns: any;
  searchText = '';
  searchBy = 'users';
  subs = new SubSink();
  Translatelangs;
  Translatelang;
  allLangs;
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<string[]>;
  selLang;
  typeSelected: string;

  ngOnInit(): void {

    this.optionSearchDSColumns = [
      { name: 'title', display: 'Title' },
      { name: 'username', display: 'Username' },
      { name: 'date', display: 'Date' },
      { name: 'view Channel', display: 'View Channel' },
    ];
    this.displayedOSColumns = this.optionSearchDSColumns.map((col: any) => col.name);

    this.msgSearchDSColumns = [
      { name: 'userid', display: 'User ID' },
      { name: 'message', display: 'Message' },
      { name: 'date', display: 'Date' },
      { name: 'view Details', display: 'View Details' },
    ];
    this.displayedMSColumns = this.msgSearchDSColumns.map((col: any) => col.name);

    const LangList = new LanguageJson();
    this.allLangs = LangList.alllangs;
    this.Translatelangs = _.map(this.allLangs, 'name');

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );
  }

  filter(val: string): string[] {
     this.selLang = this.Translatelangs.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
      return this.selLang;
  }

  listChannels(result: any): void {
    this.setOptionSearchDS(result);
    this.setMsgDatasource(result);
    this.spinnerService.hide();
  }

  private setOptionSearchDS(result: any): void {
    if (result && result.optionSearch.length > 0) {
      let chatOSDetails = result.optionSearch;
      chatOSDetails = this.formatDateArray(chatOSDetails);
      this.optionSearchDataSource = chatOSDetails;
    } else {
      this.optionSearchDataSource = [];
      this.snackbarService.openSnackBarWithAction('No channel/user found for searched keyword.', '');
    }
  }

  private setMsgDatasource(result: any): void {
    if (result && result.msgSearch.length > 0) {
      let chatMSDetails = result.msgSearch;
      chatMSDetails = this.formatDateArray(chatMSDetails);
      this.msgSearchDataSource = chatMSDetails;
      console.log(chatMSDetails);
    } else {
      this.msgSearchDataSource = [];
      this.snackbarService.openSnackBarWithAction('No messages found for searched keyword.', '');
    }
  }

  private formatDateArray(arrayDetails: any) {
    let i = 0;
    arrayDetails.forEach(function (arr: any) {
      var timestamp = +arr['date'];
      arrayDetails[i]['date'] = moment.unix(timestamp).format("DD-MM-YYYY  h:mm A");
      i = i + 1;
    });
    return arrayDetails;
  }

  searchChannel(): void {
    if (this.searchText.length > 0) {
      this.spinnerService.show();
      const lang = _.filter(this.allLangs, _.matches({ 'name': this.selLang[0]}));
      this.subs.sink = this.requestConfigService.getTelegramChannels(this.searchText, this.searchBy, lang[0].code).subscribe(this.listChannels.bind(this))
    } else {
      this.snackbarService.openSnackBarWithAction('Please enter text to search telegram.', '');
    }
  }

  channelDetails(event: any): void {
    if (event.target.id) {
      this.router.navigate(['/chatDetails'], { queryParams: { channelusername: event.target.id } })
    } else {
      this.snackbarService.openSnackBarWithAction('No username found to get channel history.', '');
    }
  }

  msgDetails(event: any): void {
    console.log(event);
    this.router.navigate(['/chatDetails'], { queryParams: { msguserid: event.target.id } })
  }

  

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
