import { Injectable } from '@angular/core';
import { Login } from './commonHttpInterface';
import { v4 as uuidv4 } from 'uuid';
import { LocalStorageInformation } from '../component/common/localStorageInformation';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

export enum TokenStatus {
  tokenNotFound = 16,
  tokenValid = 32,
  tokenNeedToRefresh = 64,
  tokenExpired = 128
}

@Injectable({
  providedIn: 'root'
})
export class UniversalService {
  localStore = new LocalStorageInformation();
  universalServiceErrors = null;

  constructor(private router: Router, private dialogRef: MatDialog) { }

  setSession(response: Login) {
    try {
      // time calculation are in millisecond:
      const date = new Date();
      const currentTime = date.getTime();

      // calculate token expire Time:
      const tokenTimeInMS = parseInt(response.expiresIn, 10);
      const tokenToBeExpireAt = currentTime + tokenTimeInMS;

      // set user_token, time_interval, expiresAt in localStorage:
      this.localStore.tokenValue = response.token;
      this.localStore.timeInterval = tokenTimeInMS.toString();
      this.localStore.expireAt = tokenToBeExpireAt;
      this.localStore.userEmail = response.user_email;
      this.localStore.userRole = response.user_role.toString();
      if (!this.localStore.uniqIdUserIdentification) {
        this.localStore.uniqIdUserIdentification = uuidv4();
      }
      // this.loadSettingsMenu();
    } catch (error) {
      console.log(error, 'set session error , universalService', this.universalServiceErrors.checkTokenStatus);
    }
  }

  handleHttpResponseError() {
    this.router.navigate(['/login']);
  }

  checkTokenStatus() {
    try {
      // time calculation in millisecond :
      let status = TokenStatus.tokenValid;  // default token status 32
      const date = new Date();
      const currentTime = date.getTime();
      // get token expire Time from localStorage:
      const tokenToBeExpireAt = this.localStore.expireAt;
      // get time Interval from localStorage:
      const tokenTimeInterval = this.localStore.timeInterval;
      // successfully done http call after approved from client side, no time out between client to server:
      const successfulCallTime = parseInt(tokenTimeInterval, 10);
      // condition to call refresh token 25% of time interval
      const timeRange = successfulCallTime * 25 / 100;
      const timeToCallRefreshToken = tokenToBeExpireAt - currentTime;
      // if token out of expire time:
      // timeToCallRefreshToken should not be negative, token expires:
      if (timeToCallRefreshToken < 0) {
        // when token has been expired :
        status = TokenStatus.tokenExpired;  // // token status 128;
      } else if (timeRange > timeToCallRefreshToken) {
        // required to refresh existing token:
        status = TokenStatus.tokenNeedToRefresh; // token status 64;
      }
      return status;
    } catch (error) {
      console.log(error, 'checkToken status error , universalService',
        this.universalServiceErrors.checkTokenStatus);
      return error;

    }
  }
  logout() {
    this.redirectToLogin();
  }

  redirectToLogin() {
    try {
      this.localStore.removeToken();
      this.localStore.removeUserRole();
      this.localStore.removeUserEmail();
      this.localStore.removeTimeInterval();
      this.localStore.removeUniqId();
      this.localStore.removeexpireAt();
      this.dialogRef.closeAll();
      this.router.navigate(['login']);
    } catch (error) {
      console.log(error, 'redirect to login error , universalService', this.universalServiceErrors.setLogout);
    }
  }

}
