<div *ngIf="monDataSource && !showMsgs">
    <h4>List of monitored channels </h4>
    <table id="tblMonChannelList" mat-table class="table-min-w" [dataSource]="monDataSource"
        class="mat-elevation-z0" matSort>
        <ng-container *ngFor="let column of monDSColumns; let i = index" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}
                <mat-icon class="matButtons" id="{{element.channelId}}" *ngIf="column.name === 'edit Details'"
                    (click)="monDetails($event)">edit</mat-icon>
                <mat-icon class="matButtons" id="{{element.channelId}}" *ngIf="column.name === 'show Messages'"
                (click)="showMessages($event)">message</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedMonColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedMonColumns"></tr>
    </table>
</div>
<div class="showMsgs" *ngIf = "showMsgs">
    <div class="backArrow" (click)="ngOnInit()"><mat-icon>arrow_back</mat-icon>Back to channel List</div>
    <app-data-table [dataSource]="dataSource" [lastReadMsgID]="lastReadMsgID" [initColumns]="initColumns" [displayedColumns]="displayedColumns"></app-data-table>
</div>
