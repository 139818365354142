import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../shared/auth/auth.service';
import { SubSink } from 'subsink';
import { UniversalService } from 'src/app/shared/universal.service';
import { LocalStorageInformation } from '../common/localStorageInformation';
import { ManageuserComponent } from '../manageuser/manageuser.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscriberComponent } from '../subscription/subscriber.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showSettings: boolean = false;
  localStore = new LocalStorageInformation();

  subs = new SubSink();
  items = [];

  matDialogRef: MatDialogRef<ManageuserComponent>;
  matDialogRefSubscriber: MatDialogRef<SubscriberComponent>;
  name: string = "";

  constructor(private router: Router, private authService: AuthService, private universalService: UniversalService,
    private matDialog: MatDialog) { }
  showHideSettings(status): void {
    this.showSettings = status;
    this.items = [
      {
        name: 'Manage Telegram'
      },
      {
        name: 'Manage Facebook'
      },
      {
        name: 'Settings'
      },
      {
        name: 'Subscribe'
      },
      {
        name: 'Log out'
      }
    ];
    if (status && this.localStore.userRole === 'admin') {
      this.items.splice(0, 0, { name: 'Manage Users' });
    }
  }

  ngOnInit(): void {
    if (this.localStore.tokenValue && this.localStore.userEmail) {
      this.authService.updateLoggedInState(true);
    }
    this.subs.sink = this.authService.loggedIn$.subscribe(this.showHideSettings.bind(this));
  }

  showLanding() {
    if (this.localStore.tokenValue && this.localStore.userEmail) {
      this.router.navigate(['/landing']);
    }
  }

  getSelectedItem(selItem: string): void {
    if (selItem === "Manage Telegram") {
      this.router.navigate(['/managetelegram']);
    } else if (selItem === "Log out") {
      this.authService.updateLoggedInState(false);
      this.showSettings = false;
      this.universalService.logout();
      // this.router.navigate(['/login']);
    } else if (selItem === "Manage Users") {
      this.OpenModal();
    } else if (selItem === "Settings") {
      this.router.navigateByUrl('/settings');
    } else if (selItem === "Subscribe") {
      this.openNotificationDialog();
    }
  }

  openNotificationDialog() {
    this.matDialogRefSubscriber = this.matDialog.open(SubscriberComponent, {
      data: { userName: this.localStore.userEmail },
      disableClose: false,
      height: '220px',
      width: '320px'
    });
  }


  OpenModal() {
    this.matDialogRef = this.matDialog.open(ManageuserComponent, {
      data: { name: this.name },
      disableClose: true
    });

    this.subs.sink = this.matDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        this.name = "";
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
