import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from './user';
import { RequestConfigService } from '../request-config.service';
import { SnackbarService } from '../snackbar.service';
import { UniversalService } from '../universal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageInformation } from 'src/app/component/common/localStorageInformation';
import { SubSink } from 'subsink/dist/subsink';

@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();
  localStore = new LocalStorageInformation();
  subs = new SubSink();

  get isLoggedIn() {
    return this.loggedIn.getValue();//.asObservable();
  }

  updateLoggedInState(status: boolean){
    this.loggedIn.next(status);
}

  constructor(
    private router: Router, private requestConfigService: RequestConfigService, private snackbarService: SnackbarService,
    private universalService: UniversalService
  ) { }

  login(user: User) {
    if (user.userName !== '' && user.password !== '') {
      this.subs.sink = this.requestConfigService.getUserLoginDetails(user).subscribe({
        next: (response) => {
          this.setLoggedInStatus(response);
        },
        error: (error) => {
          this.handleError(error);
          this.snackbarService.openSnackBarWithAction('Invalid login, Please enter correct credentials', '');
        }
      });
    }

  }
  setLoggedInStatus(userInfo: any) {
    if (userInfo.token) {
      this.universalService.setSession(userInfo);
      this.updateLoggedInState(true);
      this.router.navigate(['/landing']);
    } else {
      this.snackbarService.openSnackBarWithAction(userInfo.message, '');
    }
  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      this.universalService.handleHttpResponseError();
    }
    return of(false);
  }

  logout() {
    this.loggedIn.next(false);
    this.universalService.logout();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}