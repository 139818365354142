<div id="mainContainer">
  <h4>Hi {{data.userName}}</h4>
  <br>
  <div id="messageDiv" *ngIf="!this.checkIfAlreadySubscribed">
    <p>Do you want to subscribe Social Media app for notification?</p>
  </div>
  <div id="messageDivAlt" *ngIf="this.checkIfAlreadySubscribed">
    <p>You have already subscribed Social Media app for notification. Do you want to Unsubscribe?</p>
  </div>
  <br><br><br>
  <div id="actionButtonDiv">
    <button mat-raised-button color="action" (click)="onNoClick()">No</button>
    <button mat-raised-button  color="action" (click)="subscribeToNotifications()" cdkFocusInitial>Yes</button>
  </div>
</div>