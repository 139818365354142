<div>
    <table mat-table class="table-min-w" [dataSource]="dataSource" class="mat-elevation-z0" matSort>
        <ng-container *ngFor="let column of initColumns; let i = index" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}
                <!-- <button id="{{element.id}}" *ngIf="column.name === 'view Channel'" (click) = "channelDetails($event)" >View Details</button> -->
                <!-- <img id="{{element.id}}" style="width: 100px;" *ngIf="column.display === 'Message'"
                    src="http://localhost:3000/media/{{element.id}}.jpg"> -->
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [ngClass]="{'make-gold': row.id >  lastReadMsgID }" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
