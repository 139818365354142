<div>
    <h2 style="padding: 10px;">Forgot Password</h2>
    <div style="padding: 10px;" class="row">
        <div align="end">
            <form [formGroup]="formForgot" (ngSubmit)="onForgotSubmit()">
                <mat-form-field class="full-width-input">
                    <input matInput type="email" placeholder="Email" [(ngModel)]="forgotEmail"
                        formControlName="forgotemail" require />
                    <mat-error *ngIf="isFieldInvalid('forgotemail')">
                        Please inform your email
                    </mat-error>
                </mat-form-field>
                <a type="button" mat-stroked-button color="primary" class="btn btn-primary"
                    (click)="CloseDialog()">Cancel</a>
                <button id="btnforgot" mat-stroked-button color="primary" class="btn btn-primary"
                    type="submit">submit</button>
            </form>
        </div>
    </div>
</div>