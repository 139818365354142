<div style="width: 900px;">
    <div mat-dialog-content>
        <div style="align-items: center;border-bottom: 2px solid #142030;">
            <h2 *ngIf="isAddMode">Add User</h2>
            <h2 *ngIf="!isAddMode">Edit User</h2>
        </div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()" id="frmAddUpdate">
            <div class="form-row">
                <div class="form-group col-5">
                    <mat-label>Full Name</mat-label>
                    <input matInput formControlName="fullName" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['fullName'].errors }" />
                    <mat-error *ngIf="submitted && userForm['fullName'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['fullName'].errors?.['required']">Full Name is required</mat-error>
                    </mat-error>
                </div>
                <div class="form-group col-7">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['email'].errors }" />
                    <mat-error *ngIf="submitted && userForm['email'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['email'].errors?.['required']">Email is required</mat-error>
                        <mat-error *ngIf="userForm['email'].errors['email']">Email must be a valid email address</mat-error>
                    </mat-error>
                </div>
            </div>
            <div class="form-row">
               <div class="form-group col-7">  <!-- 0 = requested, 1 = Invited, 2 = Approved, 3 = Rejected -->
                    <mat-label>User Status</mat-label>
                    <select matInput formControlName="user_status" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['user_status'].errors }">
                        <option value="requested">requested</option>
                        <option value="approved">approved</option>
                        <option value="rejected">rejected</option>
                    </select>
                    <mat-error *ngIf="submitted && userForm['user_status'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['user_status'].errors?.['required']">User Status is required</mat-error>
                    </mat-error>
                </div>
                <div class="form-group col">
                    <mat-label>User Role</mat-label>
                    <select matInput formControlName="user_role" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['user_role'].errors }">
                        <option value="normal">normal</option>
                        <option value="admin">admin</option>
                    </select>
                    <mat-error *ngIf="submitted && userForm['user_role'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['user_role'].errors?.['required']">Role is required</mat-error>
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!isAddMode">
                <h3 class="pt-3">Change Password</h3>
                <p>Leave blank to keep the same password</p>
            </div>
            <div class="form-row">
                <div class="form-group col">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['password'].errors }" />
                    <mat-error *ngIf="submitted && userForm['password'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['password'].errors?.['required']">Password is required</mat-error>
                        <mat-error *ngIf="userForm['password'].errors?.['minlength']">Password must be at least 6 characters
                        </mat-error>
                    </mat-error>
                </div>
                <div class="form-group col">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" formControlName="confirmPassword" class="inputstyle"
                        [ngClass]="{ 'is-invalid': submitted && userForm['confirmPassword'].errors }" />
                    <mat-error *ngIf="submitted && userForm['confirmPassword'].errors" class="invalid-feedback">
                        <mat-error *ngIf="userForm['confirmPassword'].errors?.['required']">Confirm Password is required</mat-error>
                        <mat-error *ngIf="userForm['confirmPassword'].errors['mustMatch']">Passwords must match</mat-error>
                    </mat-error>
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button type="submit" mat-stroked-button color="primary" class="btn btn-primary">
                    Save
                </button>
                <a type="button" mat-stroked-button color="primary"  class="btn btn-primary" (click)="CloseDialog()">Cancel</a>
            </div>
        </form>
    </div>
</div>