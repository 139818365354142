import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UniversalService, TokenStatus } from '../../shared/universal.service';
import { RequestConfigService } from '../../shared/request-config.service';
import { LocalStorageInformation } from '../common/localStorageInformation';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';

/** logic to handle http requests with different token status:
 *  we have two aspect 1) HttpRequest,  2) token.
 *  1. HttpRequest has three possible condition:
 *     1.a) login call, 1.2) server call,  1.3) refresh call
 *  2. token can be with four possible condition:
 *     2.a) token is null, 2.b) token need not to refresh, 2.c) token to be refresh, 2.d) token has expired
 * Now HttpRequest (3)  and token (4) has 12 combination that can possible:
 *  we made it numeric formate for possible condition:
 * 	             token is null (16)   need not refresh (32)   to be refreshed (64)    expired (128)
 *  login call(2)	  (2+16) =18	        34	                  66	                  130
 *  server call
 *- token required(4)     20	              36	                  68	                  132
 *- token not required(16)32                NA                    NA                    NA
 *   refresh call(8)	    24	              40	                  72	                  136
 * For login call : all four (18, 34, 66, 130 ) possibility can go for single call i.e. generate new token
 * with login user information.
 *
 * For refresh call : all four (24, 40, 72, 136 ) possibility can go for single call i.e. attach current token and
 * send to generate new token with old user information.
 *
 * for cases where token is mandatory (accessing protected resources)
 * For server call : combination 20 and 132 can go for single call i.e.  logout current user.
 * For server call : combination 36 will be a simple server call with current attached token.
 * For Server call : combination 68 is a special case , we need give two server call and handle it.
 *
 * combination 68 :  call 1) Original http call, call 2) refresh token call.
 *  call 2 will be execute in between call 1 start and get back the response.
 * ( making a refresh token call and after we get token make the original call )
 *
 * for cases where token is not necessary (logging client errors)
 * For server call : combination 32 can call client logging directly without checking.
 * combination 32 and 18 does not need a token (for login and client logging)
*/

enum httpRequest {
  unprotected = 2,
  refreshToken = 8,
  serverCall = 4
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnDestroy {

  public token: string;
  subs = new SubSink();
  localStore = new LocalStorageInformation();
  

  constructor(private universalService: UniversalService,
    private requestConfigService: RequestConfigService, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.localStore.tokenValue;
    // request header clone here and modified by Authorization:
    const currentCase = this.queryRequest(request);
    return this.interceptAction(currentCase, request, next);
  }

  // To avoid duplicate action on http request, used addition of request_query and token_state as enum defined :
  interceptAction(currentCase: number, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // create empty Observer object of HttpEvent type
    let observe: Observable<HttpEvent<any>>;
    switch (currentCase) {
      case 130: // login user
      case 32: // client logging case
        observe = this.callServer(request, next);
        break;
      case 36:
      case 72: {
        // simple server call
        const cloned = this.attachToken(request);
        observe = this.callServer(cloned, next);
        break;
      }
      case 68:
        // refresh token call required before original call:
        // create observer to handle refresh token call:
        // inner subscriber is required:
        observe = Observable.create(this.refreshToken.bind(this, request, next));
        break;
      default:
        // logout user
        observe = this.redirectToLogin();
    }
    return observe;
  }

  refreshToken(request, next, observer) {
    // refresh existing token
    this.subs.sink = this.requestConfigService.token_refresh()
      .subscribe(this.makeServerCall.bind([request, next, this, observer]));
  }

  // server call to subscribe the original http request:
  makeServerCall(response: any): void {
    // update user info in local storage:
    this[2].universalService.setSession(response);
    const observer = this[3];

    // attach token to the original http request:
    const request = this[2].attachToken(this[0]);
    // subscribe original http request and give back response data:
    this[2].subs.sink = this[2].callServer(request, this[1]).subscribe(this[2].callNext.bind(this[2], observer));
  }

  callNext(observer, a) {
    observer.next(a);
  }

  queryRequest(request): number {
    const queryRequestType = this.getRequestType(request);
    if (queryRequestType === 8) {
      return 72;    // refresh call
    }
    if (queryRequestType === 2) {
      return 130;  // login call
    }
    if (request.url.includes('/clientLogging') && this.token === null) {
      // allow server call for client logging.
      return 32;
    }

    const tokenStatus = this.getTokenStatus(); // all other server calls
    return tokenStatus + queryRequestType;
  }

  // token status :
  getTokenStatus(): number {
    let tokenInfo;
    if (this.token !== null) {
      // call to find token status
      tokenInfo = this.universalService.checkTokenStatus();
    } else {
      // token is null;
      tokenInfo = TokenStatus.tokenNotFound;
    }
    return tokenInfo;
  }

  // attach token to request:
  attachToken(request: HttpRequest<any>) {
    const cloned = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + this.token)
    });
    return cloned;
  }

  // final call handler to server side:
  callServer(cloned, next: HttpHandler): Observable<HttpEvent<any>> {
    // called when need to be go to server:
    return next.handle(cloned).pipe();
  }

  // logged out user if token has been expired:
  redirectToLogin(): Observable<HttpEvent<any>> {
    // If token expired , this will logged out user.
    this.authService.updateLoggedInState(false);
    this.universalService.redirectToLogin();
    return new Observable<HttpEvent<any>>();
  }

  // find out which type of call going to server:
  getRequestType(request: any): number {
    // find out actual http request:
    const url = request.url;
    if (url.includes('unprotected')) {
      // request call is unprotected route includes all unprotected call.
      return httpRequest.unprotected;
    }
    if (url.includes('refresh_token')) {
      // request call is refresh token call
      return httpRequest.refreshToken;
    }
    // normal server call
    return httpRequest.serverCall;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
