import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { Login, IDataArray, IMessageInfo } from './commonHttpInterface';
import { User } from './auth/user';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RequestConfigService {
  apiServer: string;
  constructor(public config: Config, private httpClient: HttpClient,) {
    this.apiServer = config.getConfig();
  }

  getTelegramChannels(searchText: string, searchBy: string, lang: string) {
    return this.httpClient.get(this.apiServer + '/telegram/listchannels?searchText=' + searchText + '&searchBy=' + searchBy + '&lang=' + lang);
  }

  getChannelChats(channelUsername: string) {
    return this.httpClient.get(this.apiServer + '/telegram/channelmessages?channelusername=' + channelUsername);
  }

  getMessageChats(msgUserId: string) {
    return this.httpClient.get(this.apiServer + '/telegram/messageDetails?msguserid=' + msgUserId);
  }

  storeChatMonitorDetails(telMonitorDetails: IDataArray, telUsername: string, monitorStatus: boolean, channelid: number) {
    return this.httpClient.put(this.apiServer + '/telegram/storechatmonitordetails',
      { id: channelid, username: telUsername, monitorDetails: telMonitorDetails, monitorStatus: monitorStatus },
    );
  }

  getMonitorChannelDetails() {
    return this.httpClient.get(this.apiServer + '/telegram/getChannelMonitordetails');
  }

  updateMonitorStatus(channelid: number, status: boolean) {
    return this.httpClient.put(this.apiServer + '/telegram/updatemonitorstatus', { channelid: channelid, status: status });
  }

  updateMonitorText(channelid: number, monitorText: any, email: string) {
    return this.httpClient.put(this.apiServer + '/telegram/updateMonitorDetails', { id: channelid, email:email, monitorText: monitorText });
  }

  showFilteredMessages(monitorText: any, channelId: number, dateSelected?: any, imageName?: string) {
    return this.httpClient.put(this.apiServer + '/telegram/getFilteredMsgs', { monitorText: monitorText, id: channelId, dateSelected: dateSelected, imageName: imageName });
  }

  showAllMessages() {
    return this.httpClient.get(this.apiServer + '/telegram/getAllMessages');
  }

  getUserLoginDetails(user: User): Observable<Login> {
    return this.httpClient.post<Login>(this.apiServer + '/unprotected/signIn', { email: user.userName, password: user.password });
  }

  // Universal Service : refresh token
  token_refresh() {
    return this.httpClient.get(this.apiServer + '/token/refresh_token');
  }

  // LoginComponent : register the user
  registerUser(userEmail: string, pass: string, uname: string): Observable<IMessageInfo> {
    return this.httpClient.post<IMessageInfo>(this.apiServer + '/unprotected/insertUser',
      { email: userEmail, password: pass, fullName: uname }, { responseType: 'json' });
  }

  getAllUsers() {
    return this.httpClient.get(this.apiServer + '/manageusers/getallusers');
  }

  deleteuser(email: string) {
    return this.httpClient.delete(this.apiServer + '/manageusers/deleteuser?email=' + email);
  }
  
  addPushSubscriber(sub) {
    return this.httpClient.put(this.apiServer + '/telegram/addNotifySubscribers', {subscribers: sub});
  }

  deleteSubscriber(sub) {
    return this.httpClient.delete(this.apiServer + '/telegram/deleteNotifySubscribers', {body: sub});
  }

  getAllDeskNotifySubscribers() {
    return this.httpClient.get(this.apiServer + '/telegram/getAllDeskNotifySubscribers');
  }

  public uploadImage(file: any) {
    const fileformData: FormData = new FormData();
    // fileformData.append('files', file, file.name);
    fileformData.append('file', file);
    return this.httpClient.post(this.apiServer + '/telegram/uploadimage', fileformData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getUserById(email: string) {
    return this.httpClient.get(this.apiServer + '/manageusers/getUserByID?email=' + email);
  }

  createUser(userDetails: any) {
    return this.httpClient.post<IMessageInfo>(this.apiServer + '/manageusers/createuser',
      {
        email: userDetails.email, password: userDetails.password, fullName: userDetails.fullName,
        user_role: userDetails.user_role, user_status: userDetails.user_status
      }, { responseType: 'json' });
  }

  updateUser(updateDetails: any) {
    return this.httpClient.post<IMessageInfo>(this.apiServer + '/manageusers/updateuser',
      {
        email: updateDetails.email, password: updateDetails.password, fullName: updateDetails.fullName,
        user_role: updateDetails.user_role, user_status: updateDetails.user_status
      }, { responseType: 'json' });
  }

  forgotPassword(email: string) {
    return this.httpClient.post(this.apiServer + '/unprotected/forgotpassword', { email: email });
  }

  changePassword(email: string, pass: string) {
    return this.httpClient.post<IMessageInfo>(this.apiServer + '/manageusers/changepassword',
      {
        email: email, password: pass
      }, { responseType: 'json' });
  }

  getUserInfoByToken(token: string) {
    return this.httpClient.get(this.apiServer + '/manageusers/getuserinfobytoken?token=' + token);
  }

}
