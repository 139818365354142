<div style="margin-top: 120px;">
    <div class="flex">
        <div class="backArrow" *ngIf="backButton" (click)="ngOnInit()"><mat-icon>arrow_back</mat-icon>Back to Messages</div>
        <div><h3 id="lblChatTitle" [innerText]="chatHead" class="float-left"></h3></div>
        <div *ngIf="!backButton" style="font-weight: bold;color: #910558;">Do you want to monitor this chat ? 
            <mat-slide-toggle id="btnMonitorChat" class="example-margin" [color]="color" [checked]="checked"
            [disabled]="disabled" (change)="showQueryForm($event)">
          Monitor Chat!
        </mat-slide-toggle>
        </div>
    </div>
    <app-data-table [dataSource]="dataSource" [initColumns]="initColumns" [displayedColumns]="displayedColumns"></app-data-table>
</div>

<ngx-spinner size="medium" [type]="typeSelected" [fullScreen]="true"></ngx-spinner>