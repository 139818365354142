import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { Dayjs } from 'dayjs';

// import { Dayjs } from 'dayjs/esm';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, startWith } from 'rxjs';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-searchquery',
  templateUrl: './searchquery.component.html',
  styleUrls: ['./searchquery.component.css']
})
export class SearchqueryComponent implements OnInit {

  searchType;
  selType;

  searchTypes = ['All', 'Channel', 'Group', 'user'];
  selTypes = ['All', 'channel1', 'channel2', 'channel3'];
  selectedValue: string = 'Select an item';
  dateSelected: { startDate: moment.Moment, endDate: moment.Moment };

  subs = new SubSink();
  classTypeDS: any;
  classList;
  myControl: FormControl = new FormControl();
  filteredOptions;
  selClass;
  selClassType;
  searchText: string = '';

  dataSource: any;
  initColumns: any;
  displayedColumns: any;
  fileToUpload: any;
  imageUrl: any;
  selectedImage: any;
  rCount:number = 0;

  typeSelected:string;

  imageList = [];
  @ViewChild('fileUploader') fileUploader:ElementRef;

  handleFileInput(event) {
    let file = event.target.files[0];
    this.selectedImage = file;
    let reader = new FileReader();

    //Show image preview
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
  }

  constructor(private requestConfigService: RequestConfigService, private snackbarService: SnackbarService,
    private spinnerService: NgxSpinnerService) {
      this.typeSelected = 'ball-grid-pulse';
    }

  ngOnInit(): void {
    this.initColumns = [
      { name: 'date', display: 'Date' },
      { name: 'views', display: 'Views' },
      { name: 'message', display: 'Message' }
    ];
    this.displayedColumns = this.initColumns.map((col: any) => col.name);

    this.subs.sink = this.requestConfigService.getMonitorChannelDetails().subscribe(this.getclassTypeList.bind(this));
  }

  getclassTypeList(result: any): void {
    this.classTypeDS = result;
  }

  onSearchTypes(): void {
    let classTypeSet = _.filter(this.classTypeDS, _.matches({ 'classType': this.searchType.toLowerCase() }));
    if (this.searchType === 'All') {
      classTypeSet = this.classTypeDS;
    }
    this.classList = _.map(classTypeSet, 'username');
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );
    this.selClassType = '';
  }

  filter(val: string): string[] {
    this.selClass = this.classList.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
    return this.selClass;
  }

  showResults(): void {
    this.spinnerService.show();
    if (this.selectedImage) {
      this.subs.sink = this.requestConfigService.uploadImage(this.selectedImage).subscribe((event: any) => {
        if (typeof (event) === 'object' && event.body) {
          console.log(event.body.imageName);
          this.sendSearchRequest(event.body.imageName);
        }
      });
    } else {
      this.sendSearchRequest('');
    }

  }

  sendSearchRequest(imgName: string) {
    let dateSelected = [];
    if (this.dateSelected.startDate) {
      dateSelected.push({ startDate: this.dateSelected.startDate.toDate().toUTCString() });
      dateSelected.push({ endDate: this.dateSelected.endDate.toDate().toUTCString() })
    }
    if (this.selClassType) {
      let classTypeSet = _.filter(this.classTypeDS, _.matches({ 'username': this.selClassType }));
      this.subs.sink = this.requestConfigService.showFilteredMessages(this.searchText, classTypeSet[0].channelId, dateSelected, imgName).subscribe(this.showChatMessages.bind(this));
    } else {
      // this.subs.sink = this.requestConfigService.showAllMessages().subscribe(this.showChatMessages.bind(this));
      this.subs.sink = this.requestConfigService.showFilteredMessages(this.searchText, undefined, dateSelected, imgName).subscribe(this.showChatMessages.bind(this));
    }
  }

  // private uploadImage() {
  //   if (this.selectedImage) {
  //     this.requestConfigService.uploadImage(this.selectedImage).subscribe((event: any) => {
  //       if (typeof (event) === 'object') {
  //         console.log(event.body);
  //       }
  //     });
  //   }
  // }

  showChatMessages(result: any): void {
    let finalResult;
    if (result.searchedMessages.length > 0) {
      finalResult = result.searchedMessages;
      let i = 0;

      finalResult.forEach(function (arr: any) {
        var timestamp = +arr['date'];
        finalResult[i]['date'] = moment.unix(timestamp).toDate().toUTCString();//.format("DD-MM-YYYY  h:mm A");
        i = i + 1;
      });

      this.rCount = finalResult.length;
      this.dataSource = finalResult;
    } else {
      this.dataSource = '';
      this.snackbarService.openSnackBarWithAction('No chat messages found for search criteria', '');
    }
    if(result.searchedImages && result.searchedImages.length > 0) {
      this.imageList = result.searchedImages;
    } else {
      this.imageList = [];
    }
    this.spinnerService.hide();
  }

  dateTimeChangeHandler(eventArgs: any) {
    if (eventArgs.startDate === null && eventArgs.endDate === null) {
      return;
    }
    const dateValues: Date[] = [];
    dateValues.push(new Date(eventArgs.startDate));
    dateValues.push(new Date(eventArgs.endDate));
  }

  clearSearch() {
    this.selClassType = '';
    this.searchText = '';
    this.dateSelected = null;
    this.selectedImage = null;
    this.imageUrl = null;
    this.fileUploader.nativeElement.value = null;
    this.dataSource = '';
    this.imageList = [];
    this.rCount = 0;

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
