import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from './../../shared/auth/auth.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { ForgotpasswordComponent } from './forgotpassword.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


import { SubSink } from 'subsink';
import { RequestConfigService } from 'src/app/shared/request-config.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  formSignUp: FormGroup;
  private formSubmitAttempt: boolean;
  @ViewChild('container') container: ElementRef;
  matUserEditDialogRef: MatDialogRef<ForgotpasswordComponent>;
  subs = new SubSink();
  

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private requestConfigService: RequestConfigService,
    private snackbarService: SnackbarService, private matDialog: MatDialog
  ) {}

 

  signIn() {
    this.container.nativeElement.classList.remove('right-panel-active');
  }

  signUp() {
    this.container.nativeElement.classList.add('right-panel-active');
  }

  ngOnInit() {
    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.formSignUp = this.fb.group({
      fullname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      spassword: ['', Validators.required]
    });

  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  isSignUpFieldInvalid(field: string) {
    return (
      (!this.formSignUp.get(field).valid && this.formSignUp.get(field).touched) ||
      (this.formSignUp.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onLoginSubmit() {
    if (this.form.valid) {
      this.authService.login(this.form.value);
    }
    this.formSubmitAttempt = true;
  }

  onSignUpSubmit() {
    if (this.formSignUp.valid) {
      const registerUserDetails = this.formSignUp.value;
      this.subs.sink = this.requestConfigService.registerUser(registerUserDetails.email, registerUserDetails.spassword, registerUserDetails.fullname).subscribe(this.registerStatus.bind(this));
    }
    this.formSubmitAttempt = true;
  }

  registerStatus(registerStatus: any) {
    this.snackbarService.openSnackBarWithAction(registerStatus.message, '');
  }

  openForgotDailog() {
    this.matUserEditDialogRef = this.matDialog.open(ForgotpasswordComponent, {
      disableClose: true
    });
  
    this.subs.sink = this.matUserEditDialogRef.afterClosed().subscribe(res => {});
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}