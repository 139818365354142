import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

export interface matchText {
  name: string;
}

@Component({
  selector: 'app-query-dailog',
  templateUrl: './querydailog.component.html',
  styleUrls: ['./querydailog.component.css']
})
export class QueryDailogComponent {
  name: string;

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  matchTexts: matchText[] = [];

  constructor(
    private _mdr: MatDialogRef<QueryDailogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.name = data.name;
    if (data.monTags) {
      const tags = data.monTags.split(',');
      for (let t = 0; t < tags.length; t++) {
        this.matchTexts.push({ name: tags[t] });
      }
    }
  }

  confirmCloseDialog() {
    this._mdr.close({ data: this.matchTexts }) // send data to parent component
  }

  closeDialog(): void {
    this._mdr.close();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.matchTexts.push({ name: value });
    }
    event.chipInput!.clear();
  }

  remove(fruit: matchText): void {
    const index = this.matchTexts.indexOf(fruit);
    if (index >= 0) {
      this.matchTexts.splice(index, 1);
    }
  }

  edit(chipText: matchText, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(chipText);
      return;
    }
    const index = this.matchTexts.indexOf(chipText);
    if (index >= 0) {
      this.matchTexts[index].name = value;
    }
  }

}
