import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { QueryDailogComponent } from '../querydailog.component';
import * as _ from 'lodash'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SubSink } from 'subsink';
import { LocalStorageInformation } from 'src/app/component/common/localStorageInformation';

@Component({
  selector: 'app-monitordetails',
  templateUrl: './monitordetails.component.html',
  styleUrls: ['./monitordetails.component.css']
})
export class MonitordetailsComponent implements OnInit {
  typeSelected: string;
  monDataSource;//= [];
  monDSColumns: any;
  displayedMonColumns: any;
  matDialogRef: any;
  channelid: number;
  initColumns: any;
  displayedColumns: any;
  dataSource: any;
  showMsgs: boolean;
  subs = new SubSink();
  lastReadMsgID: Number;
  localStore = new LocalStorageInformation();

  constructor(private requestConfigService: RequestConfigService, private snackbarService: SnackbarService,
    private spinnerService: NgxSpinnerService, private matDialog: MatDialog, private router: Router) {
    this.typeSelected = 'ball-grid-pulse';
    this.matDialogRef = MatDialogRef<QueryDailogComponent>;
  }

  ngOnInit(): void {
    this.spinnerService.show();
    this.showMsgs = false;
    this.monDSColumns = [
      { name: 'username', display: 'Username' },
      { name: 'monitorText', display: 'Monitor Text' },
      { name: 'edit Details', display: 'Edit Details' },
      { name: 'show Messages', display: 'Show Messages' }
    ];
    this.displayedMonColumns = this.monDSColumns.map((col: any) => col.name);

    this.initColumns = [
      { name: 'date', display: 'Date' },
      { name: 'views', display: 'Views' },
      { name: 'message', display: 'Message' }
    ];
    this.displayedColumns = this.initColumns.map((col: any) => col.name);

    this.subs.sink = this.requestConfigService.getMonitorChannelDetails().subscribe(this.loadMonitoredChannels.bind(this));
    this.spinnerService.hide();
  }

  loadMonitoredChannels(result: any): void {
    if (result.length) {
      this.monDataSource = _.filter(result, _.matches({ 'monitorStatus': true }));
      // this.channelid = result[0].channelId;
      // console.log(result);
    } else {
      this.monDataSource = [];
      this.snackbarService.openSnackBarWithAction('No monitoring channels found.', '');
    }
  }

  monDetails(event: any): void {
    const monTags = _.find(this.monDataSource, function (data) { return data.channelId === Number(event.currentTarget.id); });
    this.matDialogRef = this.matDialog.open(QueryDailogComponent, {
      data: { name: event.srcElement.id, monTags: monTags.monitorText },
      // disableClose: true
    });

    this.subs.sink = this.matDialogRef.afterClosed().subscribe(this.updateMonitorText.bind(this, event.srcElement.id));
  }

  updateMonitorText(id: number, res: any): void {
    if (res && res.data.length > 0) {
      let monitorTexts = [];
      for (let i = 0; i < res.data.length; i++) {
        const jsonItems = { 'monitorText': res.data[i].name };
        monitorTexts.push(jsonItems);
      }
      this.subs.sink = this.requestConfigService.updateMonitorText(id, monitorTexts, this.localStore.userEmail).subscribe(this.monitorResult.bind(this));
    }
  }


  monitorResult(result: any): void {
    this.subs.sink = this.requestConfigService.getMonitorChannelDetails().subscribe(this.loadMonitoredChannels.bind(this));
    this.snackbarService.openSnackBarWithAction(result.monitorStatus, '');
  }

  showMessages(event: any): void {
    const monitorTexts = _.find(this.monDataSource, function (data) { return data.channelId === Number(event.currentTarget.id); }).monitorText;
    this.subs.sink = this.requestConfigService.showFilteredMessages(monitorTexts, event.srcElement.id).subscribe(this.setFilteredMessageDS.bind(this));

  }

  setFilteredMessageDS(result: any): void {
    if (result.searchedMessages.length > 0) {
      this.showMsgs = true;
      this.lastReadMsgID = result.lastReadMsgID;
      const chatMessages = result.searchedMessages;
      let i = 0;
      chatMessages.forEach(function (arr: any) {
        var timestamp = +arr['date'];
        // alert(moment(timestamp).format("DD-MM-YYYY  h:mm A"));
        chatMessages[i]['date'] = moment.unix(timestamp).format("DD-MM-YYYY  h:mm A");
        i = i + 1;
      })
      this.dataSource = chatMessages;
    } else {
      this.snackbarService.openSnackBarWithAction('No messages found for matching monitor text.', '')
    }

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
