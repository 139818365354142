<div style="width:600px">
    <h2 id="lblMonitorChat" mat-dialog-title>Monitor chat for below details</h2>
    <div mat-dialog-content></div>
    <div>
        <mat-form-field class="chip-list">
            <mat-label id="lblmatchText">Please enter below to match text</mat-label>
            <mat-chip-grid #chipGrid aria-label="Enter words">
                <mat-chip-row *ngFor="let matchText of matchTexts" (removed)="remove(matchText)"
                    [editable]="true" (edited)="edit(matchText, $event)"
                    [aria-description]="'press enter to edit ' + matchText.name">
                    {{matchText.name}}
                    <button id="btnRemoveChip" matChipRemove [attr.aria-label]="'remove ' + matchText.name">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
                <input id="txtMatchChips" placeholder="New word..." [matChipInputFor]="chipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)" />
            </mat-chip-grid>
        </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
        <span>
            <button id="btnSet" type="button" mat-flat-button color="primary" [mat-dialog-close]="true" (click)="confirmCloseDialog()">
                Set
            </button>
            <button id="btnCancelTextMatch" type="button" mat-stroked-button color="primary" (click)="closeDialog()">
                Cancel
            </button>
        </span>
    </div>
</div>