import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TelegramComponent } from './telegram/telegram.component';
import { LandingComponent } from './landing/landing.component';
import { ChatDetailsComponent } from './telegram/chatDetails.component';
import { ManagetelegramComponent } from './telegram/managetelegram.component';
import { LoginComponent } from './component/login/login.component';
import { DataTableComponent } from './component/datadisplay/dataTable.component';
import { PermissionTokenGuard } from './shared/token-guard.service';
import { PermissionsService } from './shared/auth/auth.guard';
import { SettingsComponent } from './component/manageuser/settings.component';

const allRoutes = ['/', 'landing/', 'telegramPage/', 'chatDetails/', 'login/', 'dataTable/', 'settings/'];

const routes: Routes = [{
  path: 'telegramPage',
  component: TelegramComponent,
  pathMatch: 'full'
},
{
  path: 'landing',
  canActivate: [PermissionsService],
  component: LandingComponent,
  pathMatch: 'full'
},
{
  path: 'chatDetails',
  component: ChatDetailsComponent,
  pathMatch: 'full'
},
{
  path: 'managetelegram',
  canActivate: [PermissionsService],
  component: ManagetelegramComponent,
  pathMatch: 'full'
},
{
  path: 'login',
  canActivate: [PermissionTokenGuard],
  component: LoginComponent,
  pathMatch: 'full'
},
{
  path: 'dataTable',
  component: DataTableComponent,
  pathMatch: 'full'
},
{
  path: 'settings',
  component: SettingsComponent,
  pathMatch: 'full'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
