<div style="margin-top: 80px;">
    <div class="container">
        <ul class="nav nav-tabs">
            <li class="nav-item nav-link" *ngFor="let tab of tabs" [ngClass]="{'active': tab.id === tabId}">
              <a id="{{tab.id}}" (click)="tabOnClick(tab.id)"><mat-icon>{{tab.icon}}</mat-icon>{{tab.label}}</a>
            </li>
        </ul>
        <div class="tab-body">
          <ng-container #tabBody></ng-container>
        </div>
    </div>
</div>

