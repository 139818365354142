import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { SubSink } from 'subsink';
import { AddedituserComponent } from './addedituser.component';
import { SnackbarService } from 'src/app/shared/snackbar.service';

@Component({
  selector: 'app-manageuser',
  templateUrl: './manageuser.component.html',
  styleUrls: ['./manageuser.component.css']
})
export class ManageuserComponent {
  users = null;
  subs = new SubSink();
  matUserEditDialogRef: MatDialogRef<AddedituserComponent>;

  constructor(private requestConfigService: RequestConfigService, private _mdr: MatDialogRef<ManageuserComponent>,
    private matDialog: MatDialog, private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.getAllUser();
  }

  getAllUser() {
    this.subs.sink = this.requestConfigService.getAllUsers().subscribe(this.loadAllUsers.bind(this));
  }

  loadAllUsers(userList: any): void {
    this.users = userList;
    console.log(userList);
  }

  deleteUser(email: string) {
    // const user = this.users.find(x => x.id === id);
    // user.isDeleting = true;
    // this.requestConfigService.deleteuser(id)
    //   .pipe(first())
    //   .subscribe(() => this.users = this.users.filter(x => x.id !== id));
    this.subs.sink = this.requestConfigService.deleteuser(email).subscribe(this.deleteUserStatus.bind(this));
  }

  deleteUserStatus(status: any) {
    this.snackbarService.openSnackBarWithAction(status.message, '');
    this.getAllUser();
  }

  CloseDialog() {
    this._mdr.close(false)
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  OpenModal(name: string, userid?: string) {
    this.matUserEditDialogRef = this.matDialog.open(AddedituserComponent, {
      data: { name: name,  id:userid},
      disableClose: true
    });

    this.subs.sink = this.matUserEditDialogRef.componentInstance.sendSaveUpdate.subscribe(result => {
      if(result) {
        this.getAllUser();
      }
  });

  this.subs.sink = this.matUserEditDialogRef.afterClosed().subscribe(res => {
      if ((res == true)) {
        name = "";
        
      }
    });
  }

  // approveUser(userid:string) {
  //   console.log(userid)
  // }


}
