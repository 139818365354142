import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../common/mustMatch.validator';
import { SubSink } from 'subsink';
import { LocalStorageInformation } from '../common/localStorageInformation';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { ActivatedRoute } from '@angular/router';
import { UniversalService } from 'src/app/shared/universal.service';
import { IDataArrayString, IMessageInfo } from 'src/app/shared/commonHttpInterface';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  form: FormGroup;
  private status: string;
  subs = new SubSink();
  localStore = new LocalStorageInformation();
  timer = null;
  settingsErrors = null;
  userEmail: string;

  constructor(fb: FormBuilder,
    private requestConfigService: RequestConfigService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private universalService: UniversalService,
  ) {
    this.form = fb.group({
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    }, { validator: MustMatch('newPwd', 'confirmPwd') });
  }

  ngOnInit(): void {
    this.subs.sink = this.route.queryParams.subscribe(this.validateQueryParameters.bind(this));
  }

  private validateQueryParameters(params: any) {
    let token = '';
    if (params.token === undefined) { // if reached using change password from home page.
      token = this.localStore.tokenValue;
    } else { // if reached link from email
      token = params.token;
      this.localStore.tokenValue = token;
    }
    this.subs.sink = this.requestConfigService.getUserInfoByToken(token).subscribe(this.userInfo.bind(this));
  }

  private userInfo(userInfo: IDataArrayString | IMessageInfo) {
    const info = <IDataArrayString>userInfo;
    if (info['exp'] < moment().unix()) {
      this.snackbarService.openSnackBarWithAction('Link expired, please generate new link from forgot password', '');
    }
    this.userEmail = info['email'];
    this.status = info['status'];
  }

  get newPwd() {
    return this.form.get('newPwd');
  }

  get confirmPwd() {
    return this.form.get('confirmPwd');
  }

  onSetPassword() {
    if(this.form.value.newPwd === '' && this.form.value.confirmPwd === '' ) {
      this.snackbarService.openSnackBarWithAction('Please enter new password to continue.', '');
      return;
    }
    if (this.form.invalid) {
      return;
    }
    this.subs.sink = this.requestConfigService.changePassword(this.userEmail, this.form.value.newPwd).subscribe(this.changePassStatus.bind(this));
  }

  changePassStatus(status: any) {
    this.snackbarService.openSnackBarWithAction(status.message, '');
    this.universalService.redirectToLogin();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
