
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from '../app/shared/winRef.service';

@Injectable()
export class Config {
  private config: string;

  constructor(private handler: HttpBackend, private winRef: WindowRef) { }

  public getConfig(): string {
    return this.config;
  }

  // Returns the promise to the factory method for APP_INITIALIZER
  public load(): Promise<Object> {
    const http = new HttpClient(this.handler);
    return http.get('../../assets/config.json')
      .toPromise() // TODO implement await
      .then(this.readResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  readResponse(res: any) {
    this.config = res.host;
  }

  handleError() {
    this.config = this.winRef.nativeWindow.location.origin;
    // TODO
    // FIXME
    /* error handling - error message and throwing error disabled.
    In case the server and client application served from same URL then config pointing to server is not necessary. Also to reduce the
    container creation on azure this is implemented. A proper URL string handling to determine in case of named instances to be implemented.*/

    // console.error('Error reading configuration file' + error);
    // throw new Error('Server error');
  }
}
