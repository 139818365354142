import { catchError, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { RequestConfigService } from './../shared/request-config.service';
import { Observable, of } from 'rxjs';
import { UniversalService } from './universal.service';
import { LocalStorageInformation } from '../component/common/localStorageInformation';
@Injectable()
export class PermissionTokenGuard {
  localStore = new LocalStorageInformation();
  constructor(public requestConfigService: RequestConfigService,
    private router: Router,
    private universalService: UniversalService
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const previousToken = this.localStore.tokenValue;
    if (previousToken === undefined || previousToken === null) {
      return true;
    }
    return this.requestConfigService.token_refresh().pipe(
      map(this.validatePreviousToken.bind(this)),
      catchError(this.handleError.bind(this))
    );
  }

  // validate previous token given by client:
  validatePreviousToken(response) {
    if (response.token) {
      this.router.navigate(['/landing']);
    }
    return !response.token; // dont show login if the token is still not time out.
  }

  handleError(error) {
    if (error.name === 'HttpErrorResponse') {
      this.universalService.handleHttpResponseError();
    }
    return of(false);
  }
}

export const TokenGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean => {
  return inject(PermissionTokenGuard).canActivate(next, state);
}
