<div class="container" #container>
  <div class="form-container sign-up-container">
    <form [formGroup]="formSignUp" (ngSubmit)="onSignUpSubmit()" id="frmsignup">
      <h1>Create Account</h1>
      <mat-label>Enter details to register</mat-label>
      <mat-form-field class="full-width-input">
        <input matInput type="text" placeholder="FullName" formControlName="fullname" require />
        <mat-error *ngIf="isSignUpFieldInvalid('fullname')">
          Please inform your user name
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="email" placeholder="Email" formControlName="email" require />
        <mat-error *ngIf="isSignUpFieldInvalid('email')">
          Please inform your email
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="spassword" require />
        <mat-error *ngIf="isSignUpFieldInvalid('spassword')">
          Please inform your password
        </mat-error>
      </mat-form-field>
      <button type="submit">Sign Up</button>
    </form>
  </div>
  <div class="form-container sign-in-container">
    <form [formGroup]="form" (ngSubmit)="onLoginSubmit()" id="frmlogin">
      <h1>Sign in</h1>
      <mat-label>Enter credential details </mat-label>
      <div>
        <mat-form-field class="full-width-input">
          <input id="txtUsername" matInput placeholder="Email" formControlName="userName" required>
          <mat-error *ngIf="isFieldInvalid('userName')">
            Please inform your user name
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input id="txtPassword" matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Please inform your password
          </mat-error>
        </mat-form-field>
        <button id="btnLogin" mat-raised-button color="primary" type="submit">Login</button>
      </div>
        <div class="forgotpasswordLabel">Forgot password ? <a (click)="openForgotDailog()">Click Here</a></div>
    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-left">
        <h1>Welcome Back!</h1>
        <p>To keep connected with us please login with your personal info</p>
        <button class="ghost" (click)="signIn()">Sign In</button>
      </div>
      <div class="overlay-panel overlay-right">
        <h1>Welcome, Social Apps!</h1>
        <p>Enter your personal details to register</p>
        <button class="ghost" (click)="signUp()">Sign Up</button>
      </div>
    </div>
  </div>
</div>