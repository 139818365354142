import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { MustMatch } from '../common/mustMatch.validator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UniversalService } from 'src/app/shared/universal.service';
import { of } from 'rxjs';
import { SubSink } from 'subsink/dist/subsink';

@Component({
  selector: 'app-addedituser',
  templateUrl: './addedituser.component.html',
  styleUrls: ['./addedituser.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddedituserComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  @Output() sendSaveUpdate = new EventEmitter<boolean>();
  subs = new SubSink();

  constructor(
    private formBuilder: FormBuilder,
    private requestConfigService: RequestConfigService, private _mdr: MatDialogRef<AddedituserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private snackbarService: SnackbarService,
    private universalService: UniversalService
  ) { }

  ngOnInit() {
    this.isAddMode = !this.data.id;

    // password not required in edit mode
    const passwordValidators = [Validators.minLength(6)];
    if (this.isAddMode) {
      passwordValidators.push(Validators.required);
    }

    this.form = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      user_role: ['', Validators.required],
      user_status: ['', Validators.required],
      password: ['', [Validators.minLength(6), this.isAddMode ? Validators.required : Validators.nullValidator]],
      confirmPassword: ['', this.isAddMode ? Validators.required : Validators.nullValidator]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    if (!this.isAddMode) {
      this.subs.sink = this.requestConfigService.getUserById(this.data.id).subscribe({
        next: (response) => {
          this.setUserEditDetails(response);
        },
        error: (error) => {
          this.handleError(error);
          this.snackbarService.openSnackBarWithAction('Invalid login, Please enter correct credentials', '');
        }
      });
    }
  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      this.universalService.handleHttpResponseError();
    }
    return of(false);
  }

  setUserEditDetails(userDetails) {
    console.log(userDetails);
    this.form.patchValue({ fullName: userDetails.fullName });
    this.form.patchValue({ email: userDetails.email });
    this.form.get('user_role').setValue(userDetails.user_role);
    this.form.get('user_status').setValue(userDetails.user_status);
  }

  // convenience getter for easy access to form fields
  get userForm() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createUser();
    } else {
      this.updateUser();
    }
  }

  private createUser() {
    const createUserDetails = this.form.value;
    this.subs.sink = this.requestConfigService.createUser(createUserDetails).subscribe(this.createUserStatus.bind(this));
  }

  createUserStatus(status: any) {
    this.snackbarService.openSnackBarWithAction(status.message, '');
    this.sendSaveUpdate.emit(true)
    this._mdr.close(false)
  }

  private updateUser() {
    //     this.requestConfigService.updateUser(this.id, this.form.value)
    //         .pipe(first())
    //         .subscribe({
    //             next: () => {
    //                 // this.alertService.success('User updated', { keepAfterRouteChange: true });
    //                 this.router.navigate(['../../'], { relativeTo: this.route });
    //             },
    //             error: error => {
    //                 // this.alertService.error(error);
    //                 this.loading = false;
    //             }
    //         });
    const updateUserDetails = this.form.value;
    this.subs.sink = this.requestConfigService.updateUser(updateUserDetails).subscribe(this.updateUserStatus.bind(this));
  }

  updateUserStatus(status: any) {
    this.snackbarService.openSnackBarWithAction(status.message, '');
    this.sendSaveUpdate.emit(true)
    this._mdr.close(false)
  }

  CloseDialog() {
    this._mdr.close(false)
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
