<div style="width:900px">
    <div mat-dialog-content>
        <div style="align-items: center;"><h2>Manage Users</h2></div>
        
        <table class="table table-striped" style="height: 200px;max-height: 500px;overflow-y: auto;">
            <thead>
                <tr>
                    <th style="width: 30%">Name</th>
                    <th style="width: 30%">Email</th>
                    <th style="width: 10%">Role</th>
                    <th style="width: 10%">Status</th>
                    <th style="width: 10%">Manage</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>{{user.fullName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.user_role}}</td>
                    <td>{{user.user_status}}</td>
                    <td style="white-space: nowrap">
                        <!-- <a class="userActions" (click)="approveUser(user.email)" mat-button class="btn btn-sm btn-primary mr-1">Approve</a> -->
                        <button (click)="this.OpenModal('Edit user', user.email)" mat-button class="btn btn-sm btn-primary mr-1 userActions">Edit</button>
                        <button mat-button (click)="deleteUser(user.email)" class="btn btn-sm btn-danger btn-delete-user userActions"
                            [disabled]="user.isDeleting">
                            <span *ngIf="user.isDeleting" class="spinner-border spinner-border-sm"></span>
                            <span *ngIf="!user.isDeleting">Delete</span>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="!users">
                    <td colspan="5" class="text-center">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div mat-dialog-actions align="end">
        <span>
            <button type="button" (click)="this.OpenModal('Add user')" mat-stroked-button color="primary">Add User</button>
            <button type="button" mat-stroked-button color="primary" (click)="CloseDialog()">
                Close
            </button>
        </span>
    </div>
</div>