<div class="row">
    <h4>Serach telegram data based on below filters.  <label style="margin-left: 50px;" *ngIf="rCount > 0">Result count: {{rCount}}</label></h4>
</div>
<div class="box">
    <div>
        <mat-form-field>
            <mat-select id="ddlSearchType" [(ngModel)]="searchType" placeholder="Select search type"
                (ngModelChange)="onSearchTypes()">
                <mat-option *ngFor="let searchType of searchTypes" [value]="searchType">{{ searchType }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field style="width: 350px;">
            <input id="ddlClassType" type="text" placeholder="Select option here" aria-label="Number" matInput
                [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="selClassType">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let selClass of filteredOptions | async" [value]="selClass">
                    {{ selClass }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div>
        <input id="txtFromToDate" style="height: 50px;width: 300px;" matInput type="text" ngxDaterangepickerMd
            [(ngModel)]="dateSelected" placeholder="Select from and to date here..." [timePicker]=true
            class="form-control inp" (rangeClicked)='dateTimeChangeHandler($event)' [readonly]="true"
            name="dateTimeRange" type="text" [showDropdowns]="true" [alwaysShowCalendars]="true"
            [linkedCalendars]="true" [showClearButton]="true" [keepCalendarOpeningWithRange]="false" />
    </div>
    <div>
        <mat-form-field appearance="fill" class="fld">
            <input id="txtSearchTelegram" [(ngModel)]="searchText" matInput placeholder="Enter search text here..."
                class="inp" value="">
        </mat-form-field>
    </div>
    <div style="border: 1px solid blueviolet;max-height: 50px;">
        <input class="file-input upload-btn" type="file" (change)="handleFileInput($event)" accept="image/jpeg"
            name="file" #fileUploader>

    </div>
    <div> <img width="50px" height="50px" *ngIf="imageUrl" [src]="imageUrl" class="image"></div>
    <div>
        <button id="btnMultiSearch" style="height: 50px;" type="button" mat-flat-button color="primary"
            (click)="showResults()">
            Search
        </button>
    </div>
    <div>
        <button id="btnClearSearch" style="height: 50px;" type="button" mat-flat-button color="primary"
            (click)="clearSearch()">
            Clear
        </button>
    </div>
</div>
<div class='divQureyResults'>
    <div class='row'>
        <div class='double-column'>
            <!-- <div class='blue-column'> -->
            <div class="item"><app-data-table [dataSource]="dataSource" [initColumns]="initColumns"
                    [displayedColumns]="displayedColumns"></app-data-table>
            </div>
            <!-- </div> -->
        </div>
        <div class='column'>
            <!-- <div class='green-column'> -->
            <div style="height: 50px; font-size: 14px; font-weight: bold;"> Image Search Results.</div>
            <div style="margin: 10px;" *ngFor="let item of imageList">
                <img src="data:image/png;base64, {{item._source.bufferBase64}}" width="300px" alt="Red dot" />
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>

<ngx-spinner size="medium" [type]="typeSelected" [fullScreen]="true"></ngx-spinner>