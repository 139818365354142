import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../app/shared/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { Config } from './config';
import { LandingComponent } from './landing/landing.component';
import { RequestConfigService } from './shared/request-config.service';
import { ChatDetailsComponent } from './telegram/chatDetails.component';
import { TelegramComponent } from './telegram/telegram.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { QueryDailogComponent } from './telegram/querydailog.component';
import { SettingsmenuComponent } from './component/settings/settingsmenu.component';
import { ManagetelegramComponent } from './telegram/managetelegram.component';
import { SearchqueryComponent } from './telegram/tabs/searchquery.component';
import { AnalysisComponent } from './telegram/tabs/analysis.component';
import { MonitordetailsComponent } from './telegram/tabs/monitordetails.component';
import { LoginComponent } from './component/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './shared/auth/auth.service';
import { DataTableComponent } from './component/datadisplay/dataTable.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PermissionTokenGuard } from './shared/token-guard.service';
import { PermissionsService } from './shared/auth/auth.guard';
import { TokenInterceptor } from './component/login/token.interceptor';
// import { Build } from './build';
import { UniversalService } from './shared/universal.service';
import { WindowRef } from './shared/winRef.service';
import { ManageuserComponent } from './component/manageuser/manageuser.component';
import { AddedituserComponent } from './component/manageuser/addedituser.component';
import { ForgotpasswordComponent } from './component/login/forgotpassword.component';
import { SettingsComponent } from './component/manageuser/settings.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SubscriberComponent } from './component/subscription/subscriber.component';

function initConfig(config: Config) {
  return () => config.load(); // TODO convert anonymous function to a named function
}

// function initBuild(build: Build) {
//   return () => build.load(); // TODO convert anonymous function to a named function
// }

@NgModule({
  declarations: [
    AppComponent,
    TelegramComponent,
    LandingComponent,
    HeaderComponent,
    ChatDetailsComponent,
    QueryDailogComponent,
    SettingsmenuComponent,
    ManagetelegramComponent,
    SearchqueryComponent,
    AnalysisComponent,
    MonitordetailsComponent,
    LoginComponent,
    DataTableComponent,
    ManageuserComponent,
    AddedituserComponent,
    ForgotpasswordComponent,
    SettingsComponent,
    SubscriberComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatIconModule,
    FormsModule,
    MatRadioModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [

    // Build, { provide: APP_INITIALIZER, useFactory: initBuild, deps: [Build], multi: true },
    PermissionsService,
    AuthService,
    RequestConfigService,
    PermissionTokenGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    UniversalService,
    WindowRef,
    Config,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig, // And use it here
      deps: [Config],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
