/** This class holds all the local storage variable information used in the application. The get and set methods provided to access the
 * local storage variables.
 */
export class LocalStorageInformation {
  constructor() {}

  /** This method gets the username from the local storage */
  get userName(): string {
    return localStorage.getItem(storageKey.username);
  }

  /** This method sets the username in local storage */
  set userName(value: string) {
    localStorage.setItem(storageKey.username, value);
  }

  /** This method remove the username from local storage */
  removeUserName() {
    localStorage.removeItem(storageKey.username);
  }

  /** This method gets the password from the local storage */
  get password(): string {
    return localStorage.getItem(storageKey.password);
  }

  /** This method sets the password in local storage */
  set password(value: string) {
    localStorage.setItem(storageKey.password, value);
  }

  /** This method remove the password from local storage */
  removePassword() {
    localStorage.removeItem(storageKey.password);
  }

  get checkValue(): string { //TODO make the method name meaningful and document it
    return localStorage.getItem(storageKey.checkValue);
  }

  set checkValue(value: string) { //TODO make the method name meaningful and document it
    localStorage.setItem(storageKey.checkValue, value);
  }

  /** This method gets the token value from the local storage */
  get tokenValue(): string {
    return localStorage.getItem(storageKey.userToken);
  }

  /** This method sets the token value in local storage */
  set tokenValue(value: string) {
    localStorage.setItem(storageKey.userToken, value);
  }

  /** This method remove the token value from local storage */
  removeToken() {
    localStorage.removeItem(storageKey.userToken);
  }

  /** This method gets the time interval from the local storage */
  get timeInterval(): string {
    return localStorage.getItem(storageKey.timeInterval);
  }

  /** This method sets the time interval in local storage */
  set timeInterval(value: string) {
    localStorage.setItem(storageKey.timeInterval, value);
  }

  /** This method remove the time interval from local storage */
  removeTimeInterval() {
    localStorage.removeItem(storageKey.timeInterval);
  }

  /** This method gets the session expire info from the local storage */
  get expireAt(): number {
    return parseInt(localStorage.getItem(storageKey.expireAt), 10);
  }

  /** This method sets the session expire info in local storage */
  set expireAt(value: number) {
    localStorage.setItem(storageKey.expireAt, value.toString());
  }

  /** This method gets the user email from the local storage */
  get userEmail(): string {
    return localStorage.getItem(storageKey.userEmail);
  }

  /** This method sets the user email in local storage */
  set userEmail(value: string) {
    localStorage.setItem(storageKey.userEmail, value);
  }

  /** This method remove the user email in local storage */
  removeUserEmail() {
    localStorage.removeItem(storageKey.userEmail);
  }

  /** This method gets the user role from the local storage */
  get userRole(): string {
    return localStorage.getItem(storageKey.userRole);
  }

  /** This method sets the user role in local storage */
  set userRole(value: string) {
    localStorage.setItem(storageKey.userRole, value);
  }

  /** This method remove the user role in local storage */
  removeUserRole() {
    localStorage.removeItem(storageKey.userRole);
  }

  removeexpireAt() {
    localStorage.removeItem(storageKey.expireAt);
  }

  get permissions() {
    return localStorage.getItem(storageKey.permissions);
  }

  set permissions(value: any) {
    localStorage.setItem(storageKey.permissions, value);
  }

  get uniqIdUserIdentification() {
    return localStorage.getItem(storageKey.uniqIdUserIdentification);
  }

  set uniqIdUserIdentification(value: any) {
    localStorage.setItem(storageKey.uniqIdUserIdentification, value);
  }
  removeUniqId(): void {
    localStorage.removeItem(storageKey.uniqIdUserIdentification);
  }
}

enum storageKey {
  mapLoadValue = 'mapLoadValue',
  userRole = 'user_role',
  userEmail = 'user_email',
  expireAt = 'expiresAt',
  timeInterval = 'time_interval',
  userToken = 'user_token',
  checkValue = 'chkbox',
  username = 'username',
  password = 'pass',
  permissions = 'permissions',
  uniqIdUserIdentification = 'uniqIdUserIdentification'
}

