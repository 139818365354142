import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-table',
  templateUrl: './dataTable.component.html',
  styleUrls: ['./dataTable.component.css']
})
export class DataTableComponent {
  @Input() dataSource:any;
  @Input() initColumns:any;
  @Input() displayedColumns:any;
  @Input() lastReadMsgID: Number;

}
