import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TelegramTabBaseComponent } from './tabs/telegramtab.component'
import { SearchqueryComponent } from './tabs/searchquery.component';
import { AnalysisComponent } from './tabs/analysis.component';
import { MonitordetailsComponent } from './tabs/monitordetails.component';

@Component({
  selector: 'app-managetelegram',
  templateUrl: './managetelegram.component.html',
  styleUrls: ['./managetelegram.component.css']
})
export class ManagetelegramComponent implements OnInit {

  @ViewChild('tabBody', { read: ViewContainerRef })
  componentContainer!: ViewContainerRef;

  tabId: string = 'action';
  tabs = [
    {
      id: 'searchquery',
      label: 'Search/Query',
      icon: 'search',
      component: SearchqueryComponent
    },
    {
      id: 'analysis',
      label: 'Analysis Tools',
      icon: 'flash_on',
      component: AnalysisComponent
    },
    {
      id: 'monitor',
      label: 'Monitor Details',
      icon: 'ac_unit',
      component: MonitordetailsComponent
    }
  ];

  constructor(private resolver: ComponentFactoryResolver) { }

  public ngOnInit(): void {
    this.loadTab();
  }

  tabOnClick(tabId: string) {
    this.tabId = tabId;
    this.loadTab();
  }

  loadTab() {
    const tab: any = this.tabs.find(tab => tab.id === this.tabId);
    if (this.componentContainer) {
      this.componentContainer.clear();
      const component = this.resolver.resolveComponentFactory<TelegramTabBaseComponent>(tab.component);
      const componentRef = this.componentContainer.createComponent(component);
      // This is very importent to call the life cycle hoots of component
      componentRef.changeDetectorRef.detectChanges();
      /* 
         If you want to call methods of tab component use instance attribute. For example,
         componentRef.instance.increaseCounter();
      */
    }
  }

}
