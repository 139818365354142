<div class="row" style="margin-top: 100px;">
    <div class="columnTitle" float="left">
        <h2 id="lblSearch">Search Telegram</h2>
    </div>
    <div class="columnRadio">
        <mat-radio-group id="searchRadio" [(ngModel)]="searchBy" aria-label="Select an option">
            <mat-radio-button id="radChannels" value="channels">Channels</mat-radio-button>
            <mat-radio-button id="radGroups" value="groups">Groups</mat-radio-button>
            <mat-radio-button id="radUsers" value="users" checked>Users</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="columnTranslate">
        <mat-form-field>
                <input id="txtTranslate" type="text" placeholder="Select translate language" aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let Translatelang of filteredOptions | async" [value]="Translatelang">
                    {{ Translatelang }}
                  </mat-option>
                </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="columnSearch">
        <mat-form-field appearance="fill" class="fld">
            <input id="txtSearchTelegram" [(ngModel)]="searchText" matInput placeholder="Enter search text here..."
                class="inp" value="">
            <mat-icon matSuffix class="my-icon" style="cursor:pointer;" (click)="searchChannel()">search</mat-icon>
        </mat-form-field>
    </div>
</div>

<div *ngIf="optionSearchDataSource">
    <h4>{{searchBy}} results for {{searchText}}</h4>
    <table id="tblTelegramOSList" mat-table class="table-min-w" [dataSource]="optionSearchDataSource"
        class="mat-elevation-z0" matSort>
        <ng-container *ngFor="let column of optionSearchDSColumns; let i = index" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}
                <button id="{{element.username}}" *ngIf="column.name === 'view Channel'"
                    (click)="channelDetails($event)">View History</button>
            </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedOSColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedOSColumns"></tr>
    </table>
</div>

<div *ngIf="msgSearchDataSource">
    <h4>Message results for {{searchText}}</h4>
    <table id="tblTelegramMSList" mat-table class="table-min-w" [dataSource]="msgSearchDataSource"
        class="mat-elevation-z0" matSort>
        <ng-container *ngFor="let column of msgSearchDSColumns; let i = index" [matColumnDef]="column.name">
            <th mat-header-cell *matHeaderCellDef>{{ column.display }}</th>
            <td mat-cell *matCellDef="let element">{{ element[column.name] }}
                <button id="{{element.userid}}" *ngIf="column.name === 'view Details'"
                    (click)="msgDetails($event)">View
                    History</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedMSColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedMSColumns"></tr>
    </table>
</div>
<ngx-spinner size="medium" [type]="typeSelected" [fullScreen]="true"></ngx-spinner>