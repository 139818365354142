import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
  selector: 'app-settingsmenu',
  templateUrl: './settingsmenu.component.html',
  styleUrls: ['./settingsmenu.component.css']
})
export class SettingsmenuComponent implements OnInit {
  @ViewChild("menu", { static: true })
  menu!: MatMenu;
  @Input() items:any;
  @Output() selectedItem = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  selMenuItem(selItem:string): void {
    this.selectedItem.emit(selItem);
  }

}
