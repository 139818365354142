import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RequestConfigService } from 'src/app/shared/request-config.service';
import { SnackbarService } from 'src/app/shared/snackbar.service';
import { SubSink } from 'subsink/dist/subsink';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  formForgot: FormGroup;
  private formSubmitAttempt: boolean;
  forgotEmail: string;
  subs = new SubSink();

  constructor(private _mdr: MatDialogRef<ForgotpasswordComponent>, private fb: FormBuilder,
    private requestConfigService: RequestConfigService, private snackbarService: SnackbarService){}
  ngOnInit(): void {
    this.formForgot = this.fb.group({
      forgotemail: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.formForgot.get(field).valid && this.formForgot.get(field).touched) ||
      (this.formForgot.get(field).untouched) && this.formSubmitAttempt
    );
  }

  onForgotSubmit() {
    this.subs.sink = this.requestConfigService.forgotPassword(this.forgotEmail).subscribe(this.forgotPassStatus.bind(this));
  }

  forgotPassStatus(status: any) {
    this.snackbarService.openSnackBarWithAction(status.message, '');
    this._mdr.close(false);
  }

  CloseDialog() {
    this._mdr.close(false)
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
